<template>
  <div class="login-page">
    <v-container>
      <v-row align="center" justify="center" class="mt-16">
        <v-col cols="12" lg="6">

          <div v-if="success">
            <v-card flat class="rounded">
              <v-card-text class="px-8 text-center">
                <v-icon size="80"
                        color="success"
                        class="mb-3">mdi-check-circle</v-icon>
                <p>
                  Votre rapport a été envoyé avec succès!
                </p>
              </v-card-text>
            </v-card>
          </div>

          <v-card class="rounded mb-5"
                  flat
                  v-else
                  :loading="isLoading"
                  :disabled="isLoading">
            <form>
              <v-card-text class="pa-6 pa-lg-12">


                <div class="mb-2 text-center">
                  <img alt="" src="../../assets/logo2.png"/>
                </div>

                <div class="text-center mb-5 mt-8">
                  <v-avatar color="gifty" size="70">
                    <v-icon large dark>mdi-alert</v-icon>
                  </v-avatar>
                  <h2 class="mt-2">Signaler un problème</h2>
                </div>

                <p class="text-center mb-5 grey--text text--darken-2">
                  Veuillez fournir autant de détails que possible afin que nous puissions mieux identifier le problème.
                  Quand (heure/date) le problème est-il survenu ?
                  Quel est le message d'erreur exact que vous avez reçu ?
                </p>

                <v-alert v-if="errors" type="error" dismissible>
                  <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                  </ul>
                </v-alert>

                <v-checkbox v-for="(listError, i) in listErrors"
                            v-model="form.list_error_ids"
                            color="gifty"
                            :value="listError.id"
                            hide-details
                            multiple
                            :key="i"
                            :label="listError.name"></v-checkbox>

                <v-textarea v-model="form.other"
                            class="mt-5"
                            rows="3" label="Autre ..." outlined></v-textarea>

                <v-btn block
                       color="gifty"
                       :loading="overlay"
                       @click="sendReport"
                       :disabled="!form.other && !form.list_error_ids.length"
                       depressed
                       large
                       class="rounded-lg white--text">
                  <v-icon left dark>mdi-send</v-icon>
                  Envoyer
                </v-btn>

              </v-card-text>
            </form>

          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {
        list_error_ids: [],
        other: null,
        key: this.$route.query.key,
      },
      overlay: false,
      isLoading: false,
      listErrors: [],
      errors: null,

      success: false,

    };
  },
  methods: {
    sendReport() {
      this.overlay = true;
      this.isLoading = true;
      this.errors = null;

      axios.post(`${process.env.VUE_APP_BASE_URL}v1/errors-reported/store`, this.form)
          .then(() => {
            this.overlay = false;
            this.isLoading = false;
            this.success = true;
          })
          .catch(err => {
            this.overlay = false;
            this.isLoading = false;

            if (err.response && err.response.status === 422) {
              this.errors = Object.values(err.response.data.errors).flat();
              this.$errorMessage = "Une erreur s'est produite. Veuillez la corriger.";
            } else {
              this.$errorMessage = "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.";
            }

            console.log(err);
          });
    },
    getErrors() {
      this.isLoading = true
      axios.get(process.env.VUE_APP_BASE_URL + 'v1/errors-reported/list-errors/public').then(res => {
        this.listErrors = res.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getErrors();
  },
};
</script>

<style scoped>
</style>